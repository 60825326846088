<template>
<div class="container1150">
<header><strong>EV-DA窨井综合采集仪器</strong></header>
<main>
<p>
                EV-DA
                窖井综合采集仪是一款适用于排水管网对低功耗、防水以及大容量数据存储要求而设计的采集传输一体设备。它以高性能低功耗微控制器为核心，具有多个传感器接口和多通道上报功能，可以通过
                GPRS /4G/ NB - loT
                等方式进行组网通信，主要应用于客井液位等监测场景
            </p>
<div class="img1"></div>
<h2>产品特点</h2>
<div class="img2"></div>
<div class="img3"></div>
</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            width: 100%;
            // width: 450px;
            height: 700px;
            margin: 10px auto;
            background-image: url("/img/product/13-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img2 {
            width: 1100px;
            height: 250px;
            margin: 10px auto;
            background-image: url("/img/product/13-2.png");
            background-repeat: no-repeat;
            background-position-y: -1%;
            background-size: 100% 101%;
        }
        .img3 {
            width: 1094px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/13-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>